import React, {Component} from 'react';
import {confirmAlert} from "../utils/react-confirm-alert";
import { Redirect } from 'react-router-dom';
import {validateEmail} from "../utils/HelpfulFunction";
import Confetti from 'react-confetti';
import '../../styles/css/react-confirm-alert.css';
import { unmountComponentAtNode } from "react-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import "../../styles/css/modalstyles.css";
import $ from 'jquery.transit';
import '../../styles/css/main_game.css';
import '../../styles/css/reset.css';

import bird_img from '../../styles/images/bird.png';
import pipe_img from '../../styles/images/pipe.png';
import pipe_upper_img from '../../styles/images/pipe-down.png';
import pipe_lower_img from '../../styles/images/pipe-up.png';
import land_img from '../../styles/images/land.png';
import ceiling_img from '../../styles/images/ceiling.png';
import sky_img from '../../styles/images/sky.png';
import splash_img from '../../styles/images/splash.png';

var debugmode = false;

var states = Object.freeze({
   SplashScreen: 0,
   GameScreen: 1,
   ScoreScreen: 2
});

var isIncompatible = {
   Android: function() {
   return navigator.userAgent.match(/Android/i);
   },
   BlackBerry: function() {
   return navigator.userAgent.match(/BlackBerry/i);
   },
   iOS: function() {
   return navigator.userAgent.match(/iPhone|iPad|iPod/i);
   },
   Opera: function() {
   return navigator.userAgent.match(/Opera Mini/i);
   },
   Safari: function() {
   return (navigator.userAgent.match(/OS X.*Safari/) && ! navigator.userAgent.match(/Chrome/));
   },
   Windows: function() {
   return navigator.userAgent.match(/IEMobile/i);
   },
   any: function() {
   return (isIncompatible.Android() || isIncompatible.BlackBerry() || isIncompatible.iOS() || isIncompatible.Opera() || isIncompatible.Safari() || isIncompatible.Windows());
   }
};

//Handle mouse down OR touch start
// if("ontouchstart" in window)
//    $(document).on("touchstart", screenClick());
// else
//    $(document).on("mousedown", screenClick());

class Home extends Component {
  constructor(props) {
      super(props);
      let userEmail;
      try{
          userEmail = localStorage.getItem('userEmail')
      } catch(e) {
          userEmail = false
      }
      this.state = {
        userEmail: userEmail,
        loading: false,
        startConfetti: false,
        currentGameId: null,
        width: 0,
        height: 0,
        flyArea: 0,
        score: 0,
        loopGameloop: null,
        loopPipeloop: null,
        highScore: 0,
        gravity: 0.25,
        pipewidth: 52,
        velocity: 0,
        rotation: 0,
        currentstate:0,
        position: 180,
        jump:-4.6,
        pipes:[],
        replayclickable: false,
        gameActive: false
      };
      // this.spaceFunction = this.spaceFunction.bind(this);
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.screenClickDetected = this.screenClickDetected.bind(this);
  }

  updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidMount() {
        this.currentGameKeyListener = this.props.firebase.currentGameId().on('value', snapshot => {
            if(snapshot && snapshot.exists()){
                this.logUserActivity(snapshot.val());
                if(this.state.currentGameId){
                    this.removeReactAlert();
                }
                this.setState({
                    currentGameId:snapshot.val(),
                })
            }
        });
        this.rulesRegsVariablesListener = this.props.firebase.rulesRegsVariables().on('value', snapshot => {
            if(snapshot && snapshot.exists()){
                this.setState({
                    tenantRules: snapshot.val()
                })
            }
        })
        this.currentGameActiveListener = this.props.firebase.currentGameActive().on('value', snapshot => {
            if(snapshot && snapshot.exists()){
                this.setState({
                    gameActive: snapshot.val()
                })
            }
        })
        if(this.state.userEmail){
            this.bindToUserAnswers();
        }
        this.setState({
            loading: false,
            flyArea:$("#flyarea").height()
        });
        this.showSplash();
        const vm = this
        const clickOrTouch = (('ontouchend' in window)) ? 'touchend' : 'click';
        $('#gamescreen').on(clickOrTouch, function(event) {
            event.stopPropagation();event.preventDefault();
            vm.screenClickDetected()
        });
    }

    componentWillUnmount(){
        this.currentGameKeyListener();
        this.rulesRegsVariablesListener();
        this.currentGameActiveListener();
        if(this.userhighScoreListener){
            this.userhighScoreListener()
        }
        clearInterval(this.state.loopGameloop);
        clearInterval(this.state.loopPipeloop);
        this.setState({
            loopGameloop: null,
            loopPipeloop: null
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!navigator.cookieEnabled){
            alert("This site requires cookies be enabled to work. Please change your settings in your browser and try again");
        }
    }

    logout(){
        localStorage.removeItem('userEmail');
        localStorage.removeItem('verifiedAge');
        localStorage.removeItem('birthday');
        localStorage.removeItem('locationPermissions');
        this.setState({
            userEmail:false
        })
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    removeReactAlert(){
        document.body.classList.remove('react-confirm-alert-body-element');
        const target = document.getElementById('react-confirm-alert');
        if(target){
            unmountComponentAtNode(target);
            target.parentNode.removeChild(target)
        }
        const svg = document.getElementById('react-confirm-alert-firm-svg');
        if(svg){
            svg.parentNode.removeChild(svg);
            document.body.children[0].classList.remove('react-confirm-alert-blur')
        }
    }

    logUserActivity(gameid){
        const vm = this;
        if(gameid && this.state.userEmail){
            const userEmailToBase64 = btoa(this.state.userEmail);
            this.props.firebase.db.ref('userGameHistory/'+userEmailToBase64+`/${gameid}`).set(gameid).then((err) => {
                vm.setState({
                    alreadyUpdate: gameid,
                });
            })
        }
    }

    bindToUserAnswers(){
        this.userhighScoreListener = this.props.firebase.db.ref('userAnswers/'+btoa(this.state.userEmail)+`/highScore`).on('value', snapshot => {
            if(snapshot && snapshot.exists()){
                this.setState({
                    highScore: snapshot.val()
                })
            }
        })
    }

  showSplash() {
    this.setState({
      currentstate: 0,
      velocity: 0,
      position: 180,
      rotation: 0,
      score: 0
    })
    // currentstate = states.SplashScreen;

     //set the defaults (again)

     //update the player in preparation for the next game
     $("#player").css({ y: 0, x: 0});
     this.updatePlayer($("#player"));

     // soundSwoosh.stop();
     // soundSwoosh.play();

     //clear out all the pipes if there are any
     $(".pipe").remove();
     this.setState({
       pipes: []
     })

     //make everything animated again
     $(".animated").css('animation-play-state', 'running');
     $(".animated").css('-webkit-animation-play-state', 'running');

     //fade in the splash
     $("#splash").transition({ opacity: 1 }, 2000, 'ease');
  }

  replayClicked(){
    //make sure we can only click once
     if(!this.state.replayclickable)
        return;
     else
        this.setState({
          replayclickable:false
        })
      this.showSplash()
  }

  updatePlayer(player) {
    let vm = this;
     //rotation
    let newMathResult = Math.min((this.state.velocity / 10) * 90, 90);
     this.setState({
       rotation:newMathResult
     })
       //apply rotation and position
      $(player).css({ rotate: vm.state.rotation, top: vm.state.position });
  }

  startGame() {
    this.setState({
      currentstate:1
    })
     // currentstate = states.GameScreen;

     //fade out the splash
     $("#splash").stop();
     $("#splash").transition({ opacity: 0 }, 500, 'ease');

     //update the big score
     this.setBigScore();

     // debug mode?
     if(debugmode)
     {
        //show the bounding boxes
        $(".boundingbox").show();
     }

     //start up our loops
     let updaterate = 1000.0 / 60.0 ; //60 times a second
     let vm = this;
     let loopGameloop = setInterval(function(){
       vm.gameloop()
     }, updaterate);
     let loopPipeloop = setInterval(function(){
       vm.updatePipes()
     }, 1400);
     this.setState({
       loopGameloop: loopGameloop,
       loopPipeloop: loopPipeloop
     })

     //jump from the start!
     this.playerJump();
  }

  screenClickDetected(){
    if("ontouchstart" in window)
       $(document).on("touchstart", this.screenClick());
    else
       $(document).on("mousedown", this.screenClick());
  }

  gameloop() {
     let player = $("#player");
     //update the player speed/position
     let tenantVariables = this.props.variables
     let gravity = this.state.gravity;
     let velocity = this.state.velocity;
     let position = this.state.position;
     velocity += gravity;
     position += velocity;
     this.setState({
       velocity: velocity,
       position: position
     })

     //update the player
     this.updatePlayer(player);

     //create the bounding box
     let box = document.getElementById('player').getBoundingClientRect();
     let origwidth = 34.0;
     let origheight = 24.0;

     let boxwidth = origwidth - (Math.sin(Math.abs(this.state.rotation) / 90) * 8);
     let boxheight = (origheight + box.height) / 2;
     let boxleft = ((box.width - boxwidth) / 2) + box.left;
     let boxtop = ((box.height - boxheight) / 2) + box.top;
     let boxright = boxleft + boxwidth;
     let boxbottom = boxtop + boxheight;

     //if we're in debug mode, draw the bounding box
     if(debugmode)
     {
        let boundingbox = $("#playerbox");
        boundingbox.css('left', boxleft);
        boundingbox.css('top', boxtop);
        boundingbox.css('height', boxheight);
        boundingbox.css('width', boxwidth);
     }

     //did we hit the ground?
     if(box.bottom >= $("#land").offset().top)
     {
        this.playerDead();
        return;
     }

     //have they tried to escape through the ceiling? :o
     let ceiling = $("#ceiling");
     if(boxtop <= (ceiling.offset().top + ceiling.height()))
        this.setState({
          position:0
        })

     //we can't go any further without a pipe
     if(this.state.pipes[0] == null){
       return;
     }
     //determine the bounding box of the next pipes inner area
     let nextpipe = this.state.pipes[0];
     let nextpipeupper = nextpipe.children(".pipe_upper");

     let pipetop = nextpipeupper.offset().top + nextpipeupper.height();
     let pipeleft = nextpipeupper.offset().left - 2; // for some reason it starts at the inner pipes offset, not the outer pipes.
     let piperight = pipeleft + this.state.pipewidth;
     let pipeheight = tenantVariables.pipeheight || 250;
     pipeheight = parseInt(pipeheight)
     let pipebottom = pipetop + pipeheight;

     if(debugmode)
     {
        let boundingbox = $("#pipebox");
        boundingbox.css('left', pipeleft);
        boundingbox.css('top', pipetop);
        boundingbox.css('height', pipeheight);
        boundingbox.css('width', this.state.pipewidth);
     }

     //have we gotten inside the pipe yet?
     if(boxright > pipeleft)
     {
        //we're within the pipe, have we passed between upper and lower pipes?
        if(boxtop > pipetop && boxbottom < pipebottom)
        {
          console.log("WITHIN BOUNDS")
           //yeah! we're within bounds

        }
        else
        {
           //no! we touched the pipe
           this.playerDead();
           return;
        }
     }


     //have we passed the imminent danger?
     if(boxleft > piperight)
     {
        //yes, remove it
        let pipes = this.state.pipes;
        pipes.splice(0, 1);
        this.setState({
          pipes:pipes
        })

        //and score a point
        this.playerScore();
     }
  }

  screenClick() {
          if(this.state.currentstate === states.GameScreen)
          {
              this.playerJump();
          }
          else if(this.state.currentstate === states.SplashScreen) {
              if (this.state.gameActive) {
                  this.startGame();
              } else {
                  const tenantVariables = this.props.variables;
                  const header = tenantVariables.holdingHeader || "Game Closed";
                  const body = tenantVariables.holdingMessage || "Come back later to play"
                  confirmAlert({
                      variables: this.props.variables,
                      title: header,
                      message: body,
                      buttons: [
                          {
                              label: "OK",
                              onClick: () => {
                              }
                          }
                      ]

                  });
              }
          }
  }

  playerJump() {
     // velocity = this.state.jump;
     let jump = this.state.jump;
     this.setState({
       velocity: jump
     })
     //play jump sound
     // soundJump.stop();
     // soundJump.play();
  }

  setBigScore(erase) {
     const elemscore = $("#bigscore");
     elemscore.empty();

     if(erase)
        return;

     const digits = this.state.score.toString();
     elemscore.append(`<p class="large_score_styles">${digits}</p>`);
  }

  playerDead() {
     //stop animating everything!
     $(".animated").css('animation-play-state', 'paused');
     $(".animated").css('-webkit-animation-play-state', 'paused');

     //drop the bird to the floor
     let playerbottom = $("#player").position().top + $("#player").width(); //we use width because he'll be rotated 90 deg
      let floor = this.state.flyArea;
      let movey = Math.max(0, floor - playerbottom);
     $("#player").transition({ y: movey + 'px', rotate: 90}, 1000, 'easeInOutCubic');

     //it's time to change states. as of now we're considered ScoreScreen to disable left click/flying
     this.setState({
       currentstate:states.ScoreScreen
     })
     // currentstate = states.ScoreScreen;

     //destroy our gameloops
     clearInterval(this.state.loopGameloop);
     clearInterval(this.state.loopPipeloop);
     this.setState({
       loopGameloop: null,
       loopPipeloop: null
     })
      this.showScore();
  }

  showScore() {
      //have they beaten their high score?
      const tenantVariables = this.props.variables;
     if(this.state.score > this.state.highScore) {
        //yeah!
        const highScore = this.state.score;
        const header = tenantVariables.winningHeader || "A New Best!";
        const body =  tenantVariables.winningBody || "Can you beat it?";
        const vm = this;
        this.setState({
            startConfetti: true,
        }, ()=> {
            setTimeout(() => {
                const userAnswer = {};
                userAnswer['uid'] = btoa(this.state.userEmail);
                userAnswer['highScore'] = highScore;
                userAnswer['timeStamp'] = Date.now();
                this.props.firebase.db.ref('userAnswers/'+btoa(this.state.userEmail)).set(userAnswer, function(error){
                    confirmAlert({
                        variables: tenantVariables,
                        title: header,
                        message: body,
                        buttons: [
                            {
                                label: "OK",
                                onClick: ()=>{
                                    vm.setState({
                                        replayclickable:true,
                                        highScore: highScore,
                                        startConfetti: false,
                                    }, ()=> {
                                        vm.replayClicked()
                                    })
                                }
                            }
                        ]

                    });
                })
            }, 2000);
        })
     } else {
         const header = tenantVariables.otherHeader || "Game Over";
         const body =  tenantVariables.otherMessage || "You can do better, try again"
         confirmAlert({
             variables: this.props.variables,
             title: header,
             message: body,
             buttons: [
                 {
                     label: "OK",
                     onClick: ()=>{
                         this.setState({
                             replayclickable:true
                         }, ()=> {
                             this.replayClicked()
                         })
                     }
                 }
             ]

         });
     }
  }

  playerScore() {
    let score =this.state.score
    score += 1;
    this.setState({
      score:score
    })
     //play score sound
     // soundScore.stop();
     // soundScore.play();
     this.setBigScore();
  }

  updatePipes() {
     let tenantVariables = this.props.variables;
     let pipe_img_loaded = tenantVariables.pipe_img || pipe_img;
     let pipe_upper_loaded = tenantVariables.pipe_upper_img || pipe_upper_img;
     let pipe_lower_loaded = tenantVariables.pipe_lower_img || pipe_lower_img;
     let pipeheight = tenantVariables.pipeheight || 250;
     pipeheight = parseInt(pipeheight)
     //Do any pipes need removal?
     $(".pipe").filter(function() { return $(this).position().left <= -100; }).remove()
     //add a new pipe (top height + bottom height  + pipeheight == flyArea) and put it in our tracker
     let padding = 80;
     let constraint = this.state.flyArea - pipeheight - (padding * 2); //double padding (for top and bottom)
     let topheight = Math.floor((Math.random()*constraint) + padding); //add lower padding
     let bottomheight = (this.state.flyArea - pipeheight) - topheight;
     // let newpipe = $('<div class="pipe animated"><div class="pipe_upper" style="height: ' + topheight + 'px;background-image: url(' + pipe_img_loaded + ')"><span style="position:absolute;bottom:0;width:52px;height:26px;background-image:url('+ pipe_upper_loaded +')"></span></div><div class="pipe_lower" style="height: ' + bottomheight + 'px;background-image:url(' + pipe_img_loaded + ')"><span style="position:absolute;top:0;width:52px;height:26px;background-image:url('+ pipe_lower_loaded +')"></span></div></div>');
     let newpipe = $('<div class="pipe animated"><div class="pipe_upper" style="height: ' + topheight + 'px;background-image: url(' + pipe_img_loaded + ')"></div><div class="pipe_lower" style="height: ' + bottomheight + 'px;background-image:url(' + pipe_img_loaded + ')"></div></div>');
     $("#flyarea").append(newpipe);
     let pipes = this.state.pipes;
     pipes.push(newpipe);
     this.setState({
       pipes: pipes
     })
  }

  // spaceFunction(event){
  //   //Handle space bar
  //     //space bar!
  //    if(event.keyCode == 32)
  //    {
  //       //in ScoreScreen, hitting space should click the "replay" button. else it's just a regular spacebar hit
  //       if(this.state.currentstate == states.ScoreScreen)
  //          $("#replay").click();
  //       else
  //          this.screenClick();
  //    }
  // }

  render() {
    const tenantVariables = this.props.variables || {};
    const tenantRules = this.state.tenantRules || {};
    const stringConstants = this.props.stringConstants || {};
    const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
    let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
    let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
    let rulesPopUpText = tenantRules.rulesPopUpText;
    let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
    const link = tenantRules.rulesAndRegsLink;
    const logOutColor = tenantVariables.logOutButtonColor || "white";
    const primaryColor = tenantVariables.primaryColor || "black";
    const secondaryColor = tenantVariables.secondaryColor || "white";
    const scoreDescription = tenantVariables.scoreDescription || "Your High Score";
    const bird_img_js = tenantVariables.jumping_image ||  bird_img;
    const land_img_js = tenantVariables.land_image || land_img;
    const ceiling_img_js = tenantVariables.ceiling_image || ceiling_img;
    const sky_img_js = tenantVariables.sky_image || sky_img;
    const splash_img_js = tenantVariables.splash_image || splash_img;
    const sky_background_color = tenantVariables.sky_background_color || "#4ec0ca";
    const land_background_color = tenantVariables.land_background_color || "#ded895";
      const rulesShowInApp = tenantRules.rulesShowInApp || false;
      if(rulesShowInApp) {
          if(!rulesShowInAppPopUpText && rulesPopUpText){
              rulesShowInAppPopUpText = rulesPopUpText;
          }
          if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
              rulesShowInAppPopUpHeader = rulesPopUpHeader;
          }
      }
      if(!this.state.userEmail){
          let redirectString = "/login";
          let language = "";
          let splitWindowLocation = window.location.pathname.split('/') || "";
          if(window.location.pathname.split('/').length > 1){
              language = "/" + splitWindowLocation[1]
              redirectString += language
          }
          return (
              <Redirect to={redirectString} />
          )
      } else if(!tenantVariables.doNotCollectEmail && !validateEmail(this.state.userEmail)){
          this.logout();
      } else if(tenantVariables.doNotCollectEmail && validateEmail(this.state.userEmail)){
          this.logout();
      }
      const verifiedAge = this.props.checkForAgeGate(tenantVariables);
      if(!verifiedAge){
          let urlToGo = "/age_gate";
          let langauge = "/";
          let splitWindowLocation = window.location.pathname.split('/') || "";
          if(splitWindowLocation.length > 1){
              langauge += splitWindowLocation[1];
              urlToGo += langauge
          }
          return (
              <Redirect to={urlToGo} />
          )
      }

    return(
    <>
        {this.state.startConfetti &&
            <Confetti numberOfPieces={400}/>
        }
      <div id="gamecontainer">
          <div style={{display: this.state.highScore > 0 ? 'block' : 'none', position:'absolute', top:0,left:0, zIndex:1}}>
              <p style={{padding: 5, fontSize:18, fontFamily:"Oswald", color:logOutColor}}>{scoreDescription}: {this.state.highScore}</p>
          </div>
          {/*<div style={{visibility: rulesShowInApp? "visible":"hidden",position:'absolute', bottom:0,left:"50%",zIndex:1}}>*/}
          {/*    <button className="logout_btn" onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }} style={{color: logOutColor, borderColor: logOutColor}}>{rulesInAppButtonText}</button>*/}
          {/*</div>*/}
          <div style={{position:'absolute', top:0,right:0, zIndex:1}}>
             <button className="logout_btn" onClick={() => { this.logout() }} style={{color:logOutColor, borderColor: logOutColor, backgroundColor:'transparent'}}>LOG OUT</button>
         </div>
          {/*<button className="screenButton" style={{height:100, width:100, zIndex: 1000}} onClick={() => this.screenClickDetected()}>CLICK</button>*/}
         <div id="gamescreen" onClick={() => this.screenClickDetected()}>
            <div id="sky" className="animated" style={{backgroundImage: `url(${sky_img_js})`, backgroundColor: sky_background_color}}>
               <div id="flyarea">
                  <div id="ceiling" className="animated" style={{backgroundImage: `url(${ceiling_img_js})`}}/>
                  {/*  <!-- This is the flying and pipe area container -->*/}
                  <div id="player" className="bird animated" style={{backgroundImage: `url(${bird_img_js})`}}/>

                  <div id="bigscore"/>

                  <div id="splash" style={{backgroundImage: `url(${splash_img_js})`}}/>

            {/*     <!-- Pipes go here! -->*/}
               </div>
            </div>
          <div id="land" className="animated" style={{backgroundImage: `url(${land_img_js})`, backgroundColor: land_background_color}}><div id="debug"/></div>
         </div>
      </div>
      <div className="boundingbox" id="playerbox"/>
      <div className="boundingbox" id="pipebox"/>
        {/*<Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">*/}
        {/*    <ModalHeader style={{color: secondaryColor, padding:10}}>*/}
        {/*        <span style={{fontSize:25}}>*/}
        {/*            {rulesShowInAppPopUpHeader}*/}
        {/*        </span>*/}
        {/*    </ModalHeader>*/}
        {/*    <ModalBody>*/}
        {/*        <center className="container-out">*/}
        {/*            <div className="question-box question-form">*/}
        {/*                <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>*/}
        {/*                <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>{stringConstants.DISMISSTEXT}</button>*/}
        {/*            </div>*/}
        {/*        </center>*/}
        {/*    </ModalBody>*/}
        {/*</Modal>*/}
    </>
    )
  }

}

export default Home;
