import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import MainApp from './components/mainapp/Home';
import Login from './components/mainapp/login';
import AgeVerification from './components/mainapp/AgeVerification';
import { withFirebase } from './components/Firebase';
import Loading from './components/utils/Loading';

class App extends Component {
  constructor(props) {
        super(props)
        this.state = {
          authenticated: false,
          variables: {},
          loading: true,
        }
    }

    componentDidMount() {
      let langague = "english"
      let splitWindowLocation = window.location.pathname.split('/') || ""
      if(window.location.pathname.split('/').length > 2){
        langague = splitWindowLocation[2]
      } else if(window.location.pathname.split('/').length > 1){
        langague = splitWindowLocation[1]
      }
      this.loadLanguage(langague)
      this.tenantVariablesListener = this.props.firebase.tenantVariables().on('value', snapshot => {
        let variables = {}
        if(snapshot && snapshot.val()){
          variables = snapshot.val();
        }
        this.setState({
          variables: variables,
          loading: false
        });
      });
    }

    componentWillUnmount(){
      this.tenantVariablesListener()
    }

    checkForAgeGate(tenantVariables){
      const variable_age_boolean = tenantVariables.collectBirthday || false;
      if(!variable_age_boolean){
        return true
      }
      const formBirthday = tenantVariables.formBirthday || false;
      let variable_age = tenantVariables.allowedAge || 21;
      variable_age = parseInt(variable_age, 10);
      let user_age = localStorage.getItem('verifiedAge') || false;
      if(isNaN(parseInt(user_age))){
        user_age = false
      } else {
        user_age = parseInt(user_age, 10)
      }
      return (user_age && user_age >= variable_age) || formBirthday;
    }

    async loadLanguage(langauge){
      let languagesConstants;
      if(langauge){
        try {
          languagesConstants = await import("./constants/languages/"+langauge+".js");
        } catch(e) {
          langauge = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
          languagesConstants = await import("./constants/languages/"+langauge+".js");
        }
      } else {
        langauge = process.env.REACT_APP_DEFAULT_LANGUAGE || "en";
        languagesConstants = await import("./constants/languages/"+langauge+".js");
      }
      this.setState({
        lanaguageConstants: languagesConstants
      })
    }

    render(){
      if (this.state.loading === true) {
          return (
              <Loading loading={this.state.loading} variables={this.state.variables}/>
          )
      }
      let redirectUrl = `/login`;
      if(this.state.langague){
        redirectUrl += ("/" + this.state.langague);
      }
      return (
          <div style={{ margin: "0 auto"}}>
            <BrowserRouter>
              <Switch>
                <Route
                    strict
                    path="/login"
                    render={(props) => {
                      return <Login stringConstants={this.state.lanaguageConstants} variables={this.state.variables} firebase={this.props.firebase} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />
                <Route
                    strict
                    path="/age_gate"
                    render={(props) => {
                      return <AgeVerification stringConstants={this.state.lanaguageConstants} variables={this.state.variables} firebase={this.props.firebase} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />
                <Route
                    path="/"
                    render={(props) => {
                      return <MainApp stringConstants={this.state.lanaguageConstants} variables={this.state.variables} firebase={this.props.firebase} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />

                <Redirect to={redirectUrl}/>
              </Switch>
            </BrowserRouter>
          </div>
      );
  }
}


export default withFirebase(App);
